import React, {useCallback} from 'react';
import GenericForm from 'rev.sdk.js/Generic/Form';
import styled from 'styled-components';
import * as Ant from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import qs from 'query-string';

const spec = {
  name: '會員點數',
  collection: 'user_points',
  path: '/admin/add/points',
  schema: {
    type: 'object',
    properties: {
      points: {
        type: 'integer',
        title: '新增點數',
        default: 0,
        minimum: 0,
      },
      event_name: {
        type: 'string',
        title: '補點事由',
        default: '活動',
      },
    },
  },
  uiSchema: {},
};

export default function AdminUserPoint(props) {
  const {location} = props;
  const {owner} = qs.parse(location.search) || {};

  const submit = async (formData) => {
    if (!owner) {
      return Ant.message.warning('請重新由會員頁面開啟此頁面');
    }
    AppActions.setLoading(true);
    try {
      await AppActions.addUserPoint({
        ...formData,
        user: owner,
      });
      Ant.message.success('新增點數成功');
      AppActions.navigate(`/admin/user_points/?owner=${owner}`);
    } catch (error) {
      console.warn(error);
      Ant.message.error('發生錯誤');
    }
    AppActions.setLoading(false);
  };

  return (
    <Wrapper>
      {/* <Row>
        <BackButton
          className="resource-back-button"
          onClick={() =>
            AppActions.navigate(`${spec.path}?store_id=${store_id}`)
          }>
          <ChevronLeft size={24} />
          <div className="back-btn-text">{'返回'}</div>
        </BackButton>
        {action === 'create' ? (
          <h1 style={{marginLeft: 10}}>{`創建${spec.name}`}</h1>
        ) : (
          <h1 style={{marginLeft: 10}}>{`${spec.name}詳情`}</h1>
        )}
      </Row> */}

      <h1 style={{marginLeft: 10}}>{`創建${spec.name}`}</h1>

      <GenericForm
        schema={spec.schema}
        uiSchema={spec.uiSchema}
        onSubmit={submit}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;

  & h1 {
    font-size: 32px;
  }

  & h2 {
    margin: 10px 0;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const BackButton = styled.button`
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  border-bottom: 1px solid transparent;
  padding: 5px 10px 5px 0px;
  background-color: transparent;
  cursor: pointer;

  & > .back-btn-text {
    font-size: 1.2rem;
  }

  &:hover {
    border-bottom: 1px solid black;
  }
`;
